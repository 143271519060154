.container {
    width: 600px;
    margin: 10px;
}

h4.best {
    margin: 10px 0 15px 0;
}

h5.other {
    margin: 10px 0 15px 0;
    opacity: .75;
}

.other {
    opacity: .75;
}

@media (max-width: 850px) {
    .container {
        width: calc(100% - 20px);
        margin: 10px;
    }
  }